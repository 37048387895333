<template>
  <div
    v-if="$ability.can('read', this.$route.meta.resource)"
    class="match-height"
  >
    <!-- <b-card >
      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 20%;">{{ $t('Link') }}</b-td>
                <b-td>{{ $t('Example') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ $t('Font Family') }} : </b-td>
                <b-td>
                  <b-row>
                    <b-col>
                      <b-form-input
                    id="register-link"
                    :value="`https://ui-storeapp.netlify.app/home-screen?tokenApplication=${tokenApplication}&applicationId=${applicationId}&companyId=${companyId}&franchiseId=${franchiseId}`"
                    name="register-link"
                    disabled
                    :placeholder="$t('Link')"
                  />
                    </b-col>
                    <b-col>
                      <b-button size="sm" variant="primary" @click="copy()">
            <span class="mr-25 align-middle"> {{ $t("Link") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
                </b-col>

                  </b-row>

                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card> -->
    <b-card :title="$t('Setting')">
      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 20%;">
                  {{ $t('List') }}
                </b-td>
                <b-td>{{ $t('Setting') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ $t('Font Family') }} : </b-td>
                <b-td>
                  <v-select
                    v-model="fontFamily"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="name"
                    :options="OptionFontFamily"
                    :reduce="(OptionFontFamily) => OptionFontFamily.value"
                    @input="inputChange('fontFamily')"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('BottomNavigator Render Style') }} : </b-td>
                <b-td>
                  <v-select
                    v-model="bottomNavigationRenderStyle"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="name"
                    :options="OptionBottomNavigatorRenderStyle"
                    :reduce="(OptionBottomNavigatorRenderStyle) => OptionBottomNavigatorRenderStyle.value"
                    @input="inputChange('bottomNavigationRenderStyle')"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>

    <b-card :title="$t('Color')">
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <iframe
            style="width: 100%;
                        height: 800px;
                        border: none;
                        border-radius:4px;
                        border:solid 0.5px #999;
                        "
            :src="urlWeb"
          >  <p>Your browser does not support iframes.</p>
          </iframe>
        </b-col>
        <b-col
          cols="12"
          md="7"
        >
          <b-table-simple>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 20%;">
                  {{ $t('List') }}
                </b-td>
                <b-td style="width: 20%;">
                  {{ $t('Setting') }}
                </b-td>
                <b-td style="width: 20%;" />
                <b-td style="width: 20%;" />
                <b-td style="width: 20%;" />
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ $t('Primary Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input
                    id="input-lg-primaryColor"
                    v-model="primaryColor"
                    placeholder="Primary Color"
                    type="color"
                  />
                  <b-form-input
                    id="input-lg-primaryColor"
                    v-model="primaryColor"
                    placeholder="Primary Color"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Secondary Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color"
                    type="color" />
                  <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color" />
                </b-td>
              </b-tr>
              <!-- <b-tr>
                <b-td>{{ $t('Button Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" type="color" />
                  <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" />
                </b-td>
              </b-tr> -->
              <b-tr>
                <b-td>{{ $t('Button Color') }} : </b-td>
                <b-td colspan="4">
                  <b-row>
                    <b-col md="6">
                      <span>{{ $t('Text Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorSecondText"
                        v-model="buttonColorSecondText"
                        placeholder="buttonColorSecondText"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorSecondText"
                        v-model="buttonColorSecondText"
                        placeholder="buttonColorSecondText"
                      />
                    </b-col>
                    <b-col md="6">
                      <span>{{ $t('Background Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorSecondColor"
                        v-model="buttonColorSecondColor"
                        placeholder="buttonColorSecondColor"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorSecondColor"
                        v-model="buttonColorSecondColor"
                        placeholder="buttonColorSecondColor"
                      />
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Button Color Bottom Navigation Bar') }} : </b-td>
                <b-td colspan="4">
                  <b-row>
                    <b-col md="3">
                      <span>{{ $t('Text Active Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarTextActive"
                        v-model="buttonColorBottomNavigationBarTextActive"
                        placeholder="buttonColorBottomNavigationBarTextActive"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarTextActive"
                        v-model="buttonColorBottomNavigationBarTextActive"
                        placeholder="buttonColorBottomNavigationBarTextActive"
                      />
                    </b-col>
                    <b-col md="3">
                      <span>{{ $t('Background Active Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarColorActive"
                        v-model="buttonColorBottomNavigationBarColorActive"
                        placeholder="buttonColorBottomNavigationBarColorActive"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarColorActive"
                        v-model="buttonColorBottomNavigationBarColorActive"
                        placeholder="buttonColorBottomNavigationBarColorActive"
                      />
                    </b-col>
                    <b-col md="3">
                      <span>{{ $t('Text Inactive Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarTextInactive"
                        v-model="buttonColorBottomNavigationBarTextInactive"
                        placeholder="buttonColorBottomNavigationBarTextInactive"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarTextInactive"
                        v-model="buttonColorBottomNavigationBarTextInactive"
                        placeholder="buttonColorBottomNavigationBarTextInactive"
                      />
                    </b-col>
                    <b-col md="3">
                      <span>{{ $t('Background Inctive Color') }} </span>
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarColorInactive"
                        v-model="buttonColorBottomNavigationBarColorInactive"
                        placeholder="buttonColorBottomNavigationBarColorInactive"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonColorBottomNavigationBarColorInactive"
                        v-model="buttonColorBottomNavigationBarColorInactive"
                        placeholder="buttonColorBottomNavigationBarColorInactive"
                      />
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Button In Product Color') }} : </b-td>
                <b-td colspan="4">
                  <b-row>
                    <b-col md="6">
                      <span>{{ $t('Increase') }} </span>
                      <b-form-input
                        id="input-lg-buttonIncrease"
                        v-model="buttonIncrease"
                        placeholder="buttonIncrease"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonIncrease"
                        v-model="buttonIncrease"
                        placeholder="buttonIncrease"
                      />
                    </b-col>
                    <b-col md="6">
                      <span>{{ $t('Decrease') }} </span>
                      <b-form-input
                        id="input-lg-buttonDecrease"
                        v-model="buttonDecrease"
                        placeholder="buttonDecrease"
                        type="color"
                      />
                      <b-form-input
                        id="input-lg-buttonDecrease"
                        v-model="buttonDecrease"
                        placeholder="buttonDecrease"
                      />
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr>
              <!-- <b-tr>
                <b-td>{{ $t('Error Button Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color"
                    type="color" />
                  <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color" />
                </b-td>
              </b-tr> -->
              <!-- <b-tr>
                <b-td>{{ $t('Loading Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color"
                    type="color" />
                  <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color" />
                </b-td>
              </b-tr> -->
              <!-- <b-tr>
                <b-td>{{ $t('Gradient Color') }} : </b-td>
                <b-td colspan="4">
                  <b-row>
                    <b-col md="4">
                      <span> {{ $t('Gradient Dark Color') }} </span>
                      <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color"
                        type="color" />
                      <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color" />
                    </b-col>
                    <b-col md="4">
                      <span> {{ $t('Gradient Neutral Color') }} </span>
                      <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                        placeholder="Gradient neutral Color" type="color" />
                      <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                        placeholder="Gradient neutral Color" />
                    </b-col>
                    <b-col md="4">
                      <span> {{ $t('Gradient Bright Color') }} </span>
                      <b-form-input id="input-lg-gradientBright" v-model="gradientBright"
                        placeholder="Gradient bright Color" type="color" />
                      <b-form-input id="input-lg-gradientBright" v-model="gradientBright"
                        placeholder="Gradient bright Color" />
                    </b-col>
                  </b-row>
                </b-td>
              </b-tr> -->
              <b-tr>
                <b-td>{{ $t('Title Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input
                    id="input-lg-titleColor"
                    v-model="titleColor"
                    placeholder="Title Color"
                    type="color"
                  />
                  <b-form-input
                    id="input-lg-titleColor"
                    v-model="titleColor"
                    placeholder="Title Color"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Background Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input
                    id="input-lg-backgroundColor"
                    v-model="backgroundColor"
                    placeholder="background Color"
                    type="color"
                  />
                  <b-form-input
                    id="input-lg-backgroundColor"
                    v-model="backgroundColor"
                    placeholder="background Color"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>{{ $t('Text Product Color') }} : </b-td>
                <b-td colspan="4">
                  <b-form-input
                    id="input-lg-textProductColor"
                    v-model="textProductColor"
                    placeholder="Text Product Color"
                    type="color"
                  />
                  <b-form-input
                    id="input-lg-textProductColor"
                    v-model="textProductColor"
                    placeholder="Text Product Color"
                  />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('color')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTh, BTbody, BTr, BTd,
  BBadge,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/eCommerce/applicationSettings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'

const STORE_MODULE_NAME = 'applicationSettings'

export default {
  components: {
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BBadge,
    draggable,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BListGroupItem,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fontFamily: '',
      bottomNavigationRenderStyle: 0,
      OptionFontFamily: [
        { name: 'K2D', value: 'K2D' },
        { name: 'Kanit', value: 'Kanit' },
        { name: 'Nunito', value: 'Nunito' },
        { name: 'Anuphan', value: 'Anuphan' },
      ],
    }
  },
  computed: {
    OptionBottomNavigatorRenderStyle() {
      return [
        { name: `${this.$t('Style')} 1`, value: 0 },
        { name: `${this.$t('Style')} 2`, value: 1 },
        { name: `${this.$t('Style')} 3`, value: 2 },
      ]
    },
    urlWeb() {
      if (process.env.NODE_ENV === 'production') {
        return `https://ui-storeapp.netlify.app/?tokenApplication=${this.tokenApplication}&applicationId=${this.applicationId}&companyId=${this.companyId}&franchiseId=${this.franchiseId}`
      }
      return `http://localhost:3403/?tokenApplication=${this.tokenApplication}&applicationId=${this.applicationId}&companyId=${this.companyId}&franchiseId=${this.franchiseId}`
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    tokenApplication() {
      return localStorage.getItem('tokenApplication')
    },
    applicationId() {
      return localStorage.getItem('applicationId')
    },
    companyId() {
      return localStorage.getItem('companyId')
    },
    franchiseId() {
      return localStorage.getItem('franchiseId')
    },

    primaryColor: {
      get() {
        return this.respData != null ? this.respData.color.primaryColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.primaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    secondaryColor: {
      get() {
        return this.respData != null ? this.respData.color.secondaryColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.secondaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColor: {
      get() {
        return this.respData != null ? this.respData.color.buttonColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorSecondText: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorSecond.buttonText : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorSecond.buttonText',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorSecondColor: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorSecond.buttonColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorSecond.buttonColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarTextActive: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorBottomNavigationBar.buttonTextActive : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonTextActive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarColorActive: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorBottomNavigationBar.buttonColorActive : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonColorActive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarTextInactive: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorBottomNavigationBar.buttonTextInactive : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonTextInactive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarColorInactive: {
      get() {
        return this.respData != null ? this.respData.color.buttonColorBottomNavigationBar.buttonColorInactive : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonColorInactive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonIncrease: {
      get() {
        return this.respData != null ? this.respData.color.buttonInProductPage.buttonIncrease : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonInProductPage.buttonIncrease',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonDecrease: {
      get() {
        return this.respData != null ? this.respData.color.buttonInProductPage.buttonDecrease : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonInProductPage.buttonDecrease',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    errorbtnColor: {
      get() {
        return this.respData != null ? this.respData.color.errorbtnColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.errorbtnColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    loadingColor: {
      get() {
        return this.respData != null ? this.respData.color.loadingColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.loadingColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientDark: {
      get() {
        return this.respData != null ? this.respData.color.gradient.dark : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.dark',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientNeutral: {
      get() {
        return this.respData != null ? this.respData.color.gradient.neutral : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.neutral',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientBright: {
      get() {
        return this.respData != null ? this.respData.color.gradient.bright : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.bright',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    titleColor: {
      get() {
        return this.respData != null ? this.respData.color.titleColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.titleColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    backgroundColor: {
      get() {
        return this.respData != null ? this.respData.color.backgroundColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.backgroundColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    textProductColor: {
      get() {
        return this.respData != null ? this.respData.color.textProductColor : null
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.textProductColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        if (result.data.data.fontFamily != null) {
          this.fontFamily = result.data.data.fontFamily
        }
        if (result.data.data.bottomNavigationRenderStyle != null) {
          this.bottomNavigationRenderStyle = result.data.data.bottomNavigationRenderStyle
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    copy() {
      const text = `https://ui-storeapp.netlify.app/home-screen?tokenApplication=${this.tokenApplication}&applicationId=${this.applicationId}&companyId=${this.companyId}&franchiseId=${this.franchiseId}`

      this.$copyText(text).then(
        () => {},
        // eslint-disable-next-line no-unused-vars
        e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Can not copy!',
              icon: 'BellIcon',
            },
          })
        },
      )
    },
    inputChange(name) {
      const obj = {}
      console.log(name)
      if (name === 'color') {
        obj.color = {
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor,
          buttonColor: this.buttonColor,
          buttonColorSecond: {
            buttonText: this.buttonColorSecondText,
            buttonColor: this.buttonColorSecondColor,
          },
          buttonColorBottomNavigationBar: {
            buttonTextActive: this.buttonColorBottomNavigationBarTextActive,
            buttonColorActive: this.buttonColorBottomNavigationBarColorActive,
            buttonTextInactive: this.buttonColorBottomNavigationBarTextInactive,
            buttonColorInactive: this.buttonColorBottomNavigationBarColorInactive,
          },
          buttonInProductPage: {
            buttonIncrease: this.buttonIncrease,
            buttonDecrease: this.buttonDecrease,
          },
          errorbtnColor: this.errorbtnColor,
          loadingColor: this.loadingColor,
          gradient: {
            dark: this.gradientDark,
            neutral: this.gradientNeutral,
            bright: this.gradientBright,
          },
          titleColor: this.titleColor,
          backgroundColor: this.backgroundColor,
          textProductColor: this.textProductColor,
        }
      } else if (name === 'fontFamily') {
        obj.fontFamily = this.fontFamily
      } else if (name === 'bottomNavigationRenderStyle') {
        obj.bottomNavigationRenderStyle = this.bottomNavigationRenderStyle
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
